export default function Footer() {
    return (
        <div id="footer">
            <footer>
                <p>
                    <a href="mailto:contact@mail.thesammy2010.com?subject=Hey, I saw your website">Contact Me</a>
                    <text> </text>
                    <a href="https://github.com/thesammy2010/thesammy2010.com">©2023 thesammy2010.com</a>
                </p>
            </footer>
        </div>
    )
}
